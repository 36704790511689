<template>
  <a-modal
    :visible="showModal"
    :footer="null"
    class="modal-processing-state"
    @cancel="closeModal"
  >
    <a-row>
      <a-col :span="24" class="d-flex justify-content-center">
        <div class="pbz-font subtitle-md-medium text-confirmation">
          {{ $t('fulfillment.confirm') }}
        </div>
      </a-col>

      <a-col :span="24" class="mt-3 d-flex justify-content-center">
        <div class="pbz-font body-md-regular text-caption">
          <div> {{ captionText }} </div>
        </div>
      </a-col>

      <a-col :span="24" class="mt-5 d-flex justify-content-center align-items-center">
        <a-button
          class="save pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          @click.prevent="confirmProcessState"
        >
          <div v-if="!loading">
            {{ captionButtonYes }}
          </div>
          <div v-if="loading">
            <a-icon type="loading" />
          </div>
        </a-button>
      </a-col>

      <a-col :span="24" class="d-flex justify-content-center align-items-center mt-2">
        <a-button
          class="cancel pbz-font button-sm-medium d-flex justify-content-center"
          
          @click.prevent="closeModal"
        >
          {{ $t('fulfillment.cancel') }}
        </a-button>
      </a-col>
    </a-row>

    <!--  reject modal -->
    <a-modal
      :visible="showModalRejectDesc"
      :footer="null"
      @cancel="() => rejectOrder(false)"
    >
      <a-row>
        <a-col class="pbz-font subtitle-md-medium text-confirmation">
          {{ $t('fulfillment.cancel_order') }}
        </a-col>
        <a-col>
          <fieldset class="field-set pbz-font">
            <legend style="width:auto; font-size: 13px; margin-left: 10px;color: #E00000;">
              {{ $t('fulfillment.reason_cancel') }}
            </legend>
            <a-select v-model="rejectCode" default-value="Permintaan Pembeli" style="width: 100%; font-size: 15px" @change="handleSelect">
              <a-select-option v-for="(item, value) in reject_state" :key="value" :value="item.value">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </fieldset>
        </a-col>
        <a-col v-if="rejectCode === 4" style="margin-bottom: 20px;">
          <a-range-picker
            format="YYYY-MM-DD"
            :placeholder="['Pilih Tanggal Toko Tutup']"
            :separator="''"
            size="large"
            style="display: contents;"
            @change="calendarChange"
          >
            <a-icon slot="suffixIcon" type="calendar" style="color: #E00000" />
          </a-range-picker>
          <div v-if="error.dateClosed && end_date === null" style="color: red;" class="d-flex justify-content-start">
            {{ error.dateClosed }}
          </div>
        </a-col>
        <a-col v-if="rejectCode === 1">
          <div class="pbz-font">
            Ubah informasi Stok Produk
          </div>
          <div v-for="(item, index) in outOfStockProduct" :key="index" class="d-flex" style="margin: 20px 10px;">
            <a-checkbox style="display: flex; align-items: center;" :checked="item.checked" @change="(e) => handleOutOfStockItem(e, item.order_line_item_id)">
              <img :src="item.img_url" style="max-width:60px; max-height:65" />
            </a-checkbox>
            <div style="margin: 10px 10px">
              <div class="pbz-font">
                {{ item.catalog_title }}
              </div>
              <div style="color: #999999">
                SKU: {{ item.sku }}
              </div>
            </div>
          </div>
          <div v-if="error.oosProduct && oos_line_items.length === 0" style="color: red;" class="d-flex justify-content-start">
            {{ error.oosProduct }}
          </div>
        </a-col>
        <a-col class="pbz-font" style="margin-bottom: 20px">
          Rincian Pembatalan
        </a-col>
        <a-col>
          <a-textarea
            v-model="reject_reason"
            :auto-size="{ minRows: 10, maxRows: 10 }"
            :max-length="490"
            :min-length="30"
            placeholder="Jelaskan alasan pembatalan anda"
            :style="{ borderColor: lengthReason < 30 && error.reasonDescription ? 'red' : '' }"
          />
        </a-col>
        <a-col class="d-flex" style="color: #999999; font-size: 13px">
          <a-col v-if="error.reasonDescription && lengthReason < 30" :span="12" style="color: red;" class="d-flex justify-content-start">
            {{ error.reasonDescription }}
          </a-col>
          <a-col :span="error.reasonDescription && lengthReason < 30 ? 12 : 24" class="d-flex justify-content-end">
            {{ lengthReason }} / 490 · {{ $t('fulfillment.min_char') }}
          </a-col>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <button class="reject-button-modal pbz-font" @click="() => rejectOrder(false)">
            {{ $t('fulfillment.cancel') }}
          </button>
        </a-col>
        <a-col :span="12">
          <button class="accept-button-modal pbz-font ml-1" style="border: none" @click="submitRejectOrder()">
            <div v-if="!loading">
              {{ $t('fulfillment.send') }}
            </div>
            <div v-if="loading">
              <a-icon type="loading" />
            </div>
          </button>
        </a-col>
      </a-row>
    </a-modal>
  </a-modal>
</template>

<script>
import { getFulfillmentDetail, updateFulfillmentStatus } from '@/api/fulfillment'
import { acceptFulfillmentOrder, cancelFulfillmentOrder } from '@/api/channels/index'
import { shippingDelivered } from '@/api/shipping'
import { updateDistributorshipState } from '@/api/channels/distributor'
import { urlToDetailFulfillment } from '@/utils/fulfillment/index'
import getQueryParam from '@/utils/getQueryParam'

export default {
  mixins: [getQueryParam],
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    // eslint-disable-next-line vue/require-default-prop
    modalInformationProcessingState: {
      type: Object,
    },
    forceToReasonCancelItem: {
      type: Boolean,
      default: () => false,
    },
  },
  data () {
    return {
      loading: false,
      showModalRejectDesc: false,
      reject_reason: '',
      reject_state: [
        {
          value: 1,
          title: 'Produk Habis',
        },
        {
          value: 2,
          title: 'Varian Produk Tidak Tersedia',
        },
        {
          value: 3,
          title: 'Salah Harga atau Berat',
        },
        {
          value: 4,
          title: 'Toko Tutup',
        },
        {
          value: 5,
          title: 'Lainnya',
        },
        {
          value: 7,
          title: 'Kurir Bermasalah',
        },
        {
          value: 8,
          title: 'Permintaan Pembeli',
        },
      ],
      rejectCode: null,
      start_date: null,
      end_date: null,
      outOfStockProduct: [],
      oos_line_items: [],
      rejectChannel: {},
      rejectOrderId: '',
      error: {
        reasonDescription: '',
        oosProduct: '',
        dateClosed: '',
      },
    }
  },
  computed: {
    captionText () {
      if (this.modalInformationProcessingState.action === 'reject-order') {
        return this.$t('fulfillment.reject_msg')
      }

      if (this.modalInformationProcessingState.action === 'accept-order') {
        return this.$t('fulfillment.accept_msg')
      }

      // <!-- DALAM PROSES KE PENGAMBILAN  -->
      if (this.modalInformationProcessingState.action === 'pick-confirmed') {
        return this.$t('fulfillment.pick_confirmed_msg')
      }

      // <!-- PENGAMBILAN KE PENGEMASAN -->
      if (this.modalInformationProcessingState.action === 'pack-confirmed') {
        return this.$t('fulfillment.pack_confirmed_msg')
      }

      // <!-- PENGEMASAN KE GOOD ISSUED -->
      if (this.modalInformationProcessingState.action === 'packing-completed') {
        return this.$t('fulfillment.packing_completed_msg')
      }

      // <!-- GOOD ISSUED KE SHIPPED -->
      if (this.modalInformationProcessingState.action === 'good-issued') {
        return this.$t('fulfillment.good_issued_msg')
      }

      // <!-- SHIPPED KE DELIVERED -->
      if (this.modalInformationProcessingState.action === 'shipped') {
        return this.$t('fulfillment.shipped_msg')
      }

      return null
    },
    captionButtonYes() {
      if (this.modalInformationProcessingState.action === 'reject-order') {
        return this.$t('fulfillment.reject_order')
      }

      if (this.modalInformationProcessingState.action === 'accept-order') {
        return this.$t('fulfillment.accept_order')
      }

      // <!-- DALAM PROSES KE PENGAMBILAN  -->
      if (this.modalInformationProcessingState.action === 'pick-confirmed') {
        return this.$t('fulfillment.btn_caption_accept_pick_confirmed')
      }

      // <!-- PENGAMBILAN KE PENGEMASAN -->
      if (this.modalInformationProcessingState.action === 'pack-confirmed') {
        return this.$t('fulfillment.btn_caption_accept_pack_confirmed')
      }

      // <!-- PENGEMASAN KE GOOD ISSUED -->
      if (this.modalInformationProcessingState.action === 'packing-completed') {
        return this.$t('fulfillment.btn_caption_packing_completed')
      }

      // <!-- GOOD ISSUED KE SHIPPED -->
      if (this.modalInformationProcessingState.action === 'good-issued') {
        return this.$t('fulfillment.btn_caption_good_issued')
      }

      // <!-- SHIPPED KE DELIVERED -->
      if (this.modalInformationProcessingState.action === 'shipped') {
        return this.$t('fulfillment.btn_caption_shipped')
      }

      return null
    },
    lengthReason() {
      return this.reject_reason.length
    },
    getUrlToDetailFulfillment: function () {
      return urlToDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
  },
  methods: {
    confirmProcessState() {
      if (this.modalInformationProcessingState.action === 'reject-order') {
        this.$emit('setShowModalRejectDesc', true)
        this.rejectOrder(true)
        // if (this.modalInformationProcessingState.channelCode === 'distributor') {
        //   this.cancelOrderDistributorship()
        // } else {
        //   this.rejectOrder(true)
        // }
      }

      if (this.modalInformationProcessingState.action === 'accept-order') {
        this.acceptOrder()
      }

      // <!-- DALAM PROSES KE PENGAMBILAN  -->
      if (this.modalInformationProcessingState.action === 'pick-confirmed') {
        this.pickConfirmed()
      }

      // <!-- PENGAMBILAN KE PENGEMASAN -->
      if (this.modalInformationProcessingState.action === 'pack-confirmed') {
        this.packConfirmed()
      }

      // <!-- PENGEMASAN KE GOOD ISSUED -->
      if (this.modalInformationProcessingState.action === 'packing-completed') {
        this.packingCompleted()
      }

      // <!-- GOOD ISSUED KE DELIVERED -->
      if (this.modalInformationProcessingState.action === 'good-issued') {
        this.goodIssued()
      }
    },
    async acceptOrder () {
      const order_id = Array.isArray(this.modalInformationProcessingState.orderId) ? this.modalInformationProcessingState.orderId : [this.modalInformationProcessingState.orderId]
      const channel_code = this.modalInformationProcessingState.channelCode
      const business_id = this.modalInformationProcessingState.business_id

      this.loading = true

      if (channel_code === 'distributor') {
        await updateDistributorshipState({
          business_id,
          type: 'state',
          data: { 'new_status': 'ACCEPTED' },
          params: { id: this.modalInformationProcessingState.orderId },
        })
        .then(() => {
          setTimeout(() => {
            this.$notification.success({
              message: this.$t('fulfillment.notification_success_accept_order'),
            })
            this.reloadPage()
            this.loading = false
            this.$emit('setModalInformationProcessingState', {
              show: false,
              orderId: null,
              fulfillmentId: null,
              state: null,
              action: null,
            })
          }, 3000)
        })
        .catch((err) => {
          this.loading = false
          this.$notification.error({
            message: err.response.data.message,
          })
        })
      } else {
        await acceptFulfillmentOrder({
          channel_code,
          data: { order_id },
        })
        .then(() => {
          setTimeout(() => {
            this.$notification.success({
              message: this.$t('fulfillment.notification_success_accept_order'),
            })
            this.reloadPage()
            this.loading = false
            this.$emit('setModalInformationProcessingState', {
              show: false,
              orderId: null,
              fulfillmentId: null,
              state: null,
              action: null,
            })
          }, 3000)
        })
        .catch((err) => {
          this.loading = false
          this.$notification.error({
            message: err,
          })
        })
      }
    },
    async pickConfirmed() {
      // <!-- DALAM PROSES KE PENGAMBILAN  -->
      const fulfillment_ids = Array.isArray(this.modalInformationProcessingState.fulfillmentId) ? this.modalInformationProcessingState.fulfillmentId : [this.modalInformationProcessingState.fulfillmentId]

      this.loading = true

      await updateFulfillmentStatus({
        url: 'delivery-order',
        type: 'pick_confirmed',
        data: { fulfillment_ids },
      })
      .then(() => {
        setTimeout(() => {
          this.$notification.success({
            message: this.$t('fulfillment.notification_success_update_order'),
          })
          this.reloadPage()
          this.loading = false
          this.$emit('setModalInformationProcessingState', {
            show: false,
            orderId: null,
            fulfillmentId: null,
            state: null,
            action: null,
          })
        }, 3000)
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: err,
        })
      })
    },
    async packConfirmed() {
      // <!-- PENGAMBILAN KE PENGEMASAN -->
      const fulfillment_ids = Array.isArray(this.modalInformationProcessingState.fulfillmentId) ? this.modalInformationProcessingState.fulfillmentId : [this.modalInformationProcessingState.fulfillmentId]

      this.loading = true
      await updateFulfillmentStatus({
        url: 'delivery-order',
        type: 'packing_completed',
        data: { fulfillment_ids },
      })
      .then(() => {
        setTimeout(() => {
          this.$notification.success({
            message: this.$t('fulfillment.notification_success_update_order'),
          })
          this.reloadPage()
          this.loading = false
          this.$emit('setModalInformationProcessingState', {
            show: false,
            orderId: null,
            fulfillmentId: null,
            state: null,
            action: null,
          })
        }, 3000)
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: err,
        })
      })
    },
    async packingCompleted() {
      // <!-- PENGEMASAN KE GOOD ISSUED -->
      const fulfillment_ids = Array.isArray(this.modalInformationProcessingState.fulfillmentId) ? this.modalInformationProcessingState.fulfillmentId : [this.modalInformationProcessingState.fulfillmentId]

      this.loading = true
      await updateFulfillmentStatus({
        url: 'delivery-order',
        type: 'good_issued',
        data: { fulfillment_ids },
      })
      .then(() => {
        setTimeout(() => {
          this.$notification.success({
            message: this.$t('fulfillment.notification_success_update_order'),
          })
          this.reloadPage()
          this.loading = false
          this.$emit('setModalInformationProcessingState', {
            show: false,
            orderId: null,
            fulfillmentId: null,
            state: null,
            action: null,
          })
        }, 3000)
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: err,
        })
      })
    },
    async goodIssued() {
      // <!-- GOOD ISSUED KE DELIVERED -->
      const fulfillment_id = Array.isArray(this.modalInformationProcessingState.fulfillmentId) ? this.modalInformationProcessingState.fulfillmentId : [this.modalInformationProcessingState.fulfillmentId]

      this.loading = true
      await shippingDelivered({ fulfillment_id })
      .then(() => {
        setTimeout(() => {
          this.$notification.success({
            message: this.$t('fulfillment.notification_success_update_order'),
          })
          this.reloadPage()
          this.loading = false
          this.$emit('setModalInformationProcessingState', {
            show: false,
            orderId: null,
            fulfillmentId: null,
            state: null,
            action: null,
          })
        }, 3000)
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: err,
        })
      })
    },
    reloadPage () {
      this.$emit('triggerFulfillmentList')
    },
    async cancelOrderDistributorship() {
      this.loading = true
      await updateDistributorshipState({
        business_id: this.modalInformationProcessingState.business_id,
        type: 'cancel',
        data: {
          reason_code: 'CANCELLED_BY_USER',
          reason: 'Cancel by user',
          new_status: 'CANCELED',
        },
        params: { id: this.modalInformationProcessingState.orderId },
      })
      .then(() => {
        setTimeout(() => {
          this.$notification.success({
            message: 'Success cancel order',
          })
          this.reloadPage()
          this.loading = false
          this.$emit('setModalInformationProcessingState', {
            show: false,
            orderId: null,
            fulfillmentId: null,
            state: null,
            action: null,
          })
          this.showModalRejectDesc = false
          this.$router.push({
            path: this.getUrlToDetailFulfillment,
            query: {
              ...this.getQueryParam(this.$route.query),
              fulfillment_id: this.$route.query.fulfillment_id,
            },
          })
        }, 3000)
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: err.response.data.message,
        })
      })
    },
    rejectOrder(param) {
      this.fetchDetailFulfillmentBasedFulfillmentId()
      this.showModalRejectDesc = param

      if (param) {
        // eslint-disable-next-line vue/no-mutating-props
        this.modalInformationProcessingState.show = false
      }else {
        this.closeModal()
      }
    },
    async submitRejectOrder() {
      if (this.lengthReason < 30) {
        this.error.reasonDescription = 'Rincian pembatalan minimal 30 karakter'
      } else {
        this.error.reasonDescription = null
      }
      if (this.oos_line_items.length === 0) {
        this.error.oosProduct = 'Pilih produk yang habis'
      } else {
        this.error.oosProduct = null
      }
      if (this.end_date === null) {
        this.error.dateClosed = 'Masukkan tanggal toko tutup'
      } else {
        this.error.dateClosed = null
      }

      const order_id = this.rejectOrderId
      let payload = {
        reason_code: this.rejectCode,
        reason: this.reject_reason,
      }
      let business_id = this.$route.query.business_id
      if(!business_id) {
        business_id = this.$store.state.user?.businessList?.find((val) => val.warehouse_id === this.$route.query?.warehouse_id)?.business_id
      }
      if (this.rejectCode === 1) {
        payload = {
          ...payload,
          oos_line_items: this.oos_line_items,
        }
      } else if (this.rejectCode === 4) {
        payload = {
          ...payload,
          shop_close_end_date: this.end_date.endOf('day').toISOString(),
        }
      }
      if (
        (this.error.reasonDescription === null && this.error.oosProduct === null) ||
        (this.error.reasonDescription === null && this.error.dateClosed === null) ||
        (this.error.reasonDescription === null && (this.rejectCode !== 1 && this.rejectCode !== 4))
      ) {
        this.loading = true
        if (this.modalInformationProcessingState.channelCode === 'distributor') {
          this.cancelOrderDistributorship()
        } else {
          await cancelFulfillmentOrder({
            business_id: business_id,
            channel_code: this.rejectChannel.channel_code,
            channel_id: this.rejectChannel.channel_id,
            order_id,
            data: payload,
          })
          .then(() => {
            setTimeout(() => {
              this.$notification.success({
                message: 'Success cancel order',
              })
              this.reloadPage()
              this.loading = false
              this.$emit('setModalInformationProcessingState', {
                show: false,
                orderId: null,
                fulfillmentId: null,
                state: null,
                action: null,
              })
              this.showModalRejectDesc = false
            }, 3000)
          })
          .catch((err) => {
            this.loading = false
            this.$notification.error({
              message: err,
            })
          })
        }
      }
    },
    calendarChange(value) {
      // console.log(value)
      if (value.length > 0) {
        this.start_date = value[0]
        this.end_date = value[1]
      } else {
        // console.log('sini')
        this.start_date = null
        this.end_date = null
      }
    },
    handleOutOfStockItem(e, id) {
      this.outOfStockProduct = this.outOfStockProduct.map((item) => {
        if (item.order_line_item_id === id) {
          return {
            ...item,
            checked: e.target.checked,
          }
        } else {
          return item
        }
      })
      this.oos_line_items = this.outOfStockProduct.filter((item) => item.checked === true).map(item => { return item.order_line_item_id })
    },
    fetchDetailFulfillmentBasedFulfillmentId: async function () {
      await getFulfillmentDetail({
        url: 'delivery-order',
        fulfillment_id: this.modalInformationProcessingState.fulfillmentId,
      })
      .then(({ data: { data: response } }) => {
        this.outOfStockProduct = response.fulfillments[0].line_items.map(item => ({
          ...item,
          checked: false,
        }))
        this.rejectChannel = {
          channel_id: response.order.channel.id,
          channel_code: response.order.channel.code,
        }
        this.rejectOrderId = response.order.id
      })
      .catch(err => console.error(err))
    },
    handleSelect() {
      this.error.dateClosed = null
      this.end_date = null
      this.reject_reason = ''
      this.outOfStockProduct = this.outOfStockProduct.map(item => {
        return {
          ...item,
          checked: false,
        }
      })
    },
    closeModal() {
      const yAxis = window.top.scrollY
      this.$emit('setModalInformationProcessingState', {
        show: false,
        orderId: null,
        fulfillmentId: null,
        state: null,
        action: null,
      })
      this.$router.push({
        path: this.getUrlToDetailFulfillment,
          query: {
            ...this.getQueryParam(this.$route.query),
            fulfillment_id: this.$route.query.fulfillment_id,
          },
      }).then(() => window.scrollTo(0, yAxis))
    },
  },
  // watch: {
  //   forceToReasonCancelItem: {
  //     immediate: true,
  //     deep: true,
  //     handler: function (value) {
  //       if (value) {
  //         this.rejectOrder(true)
  //       }
  //     },
  //   },
  // },
}
</script>

<style lang="scss">
.modal-processing-state {
  .cancel {
    color: #E00000;
    padding: .75rem;
    cursor: pointer;
    border: 1px solid #E00000;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .save {
    border: 1px solid #E00000;
    background: #E00000;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    padding: .75rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

</style>
